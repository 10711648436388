const GameIcon = () => (
  <svg width='60' height='54' viewBox='0 0 60 54' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M40.172 0.0390625L40.9199 0.476756C47.8467 4.4826 52.2425 10.1583 54.0113 18.1177C55.2337 23.6317 57.4963 28.9173 59.5 34.9118L48.4363 38.38C45.4115 34.6453 42.5305 31.101 39.4674 27.3235L21.5393 34.5597C22.2487 39.9785 22.9198 45.1167 23.6628 50.7686L13.8551 53.9609C12.0191 50.8971 10.2503 47.9474 8.49101 44.993C7.35492 43.09 6.50637 40.93 5.0491 39.2839C-0.319763 33.318 0.46642 26.215 0.643784 19.0835C0.720482 16.0529 3.62064 15.3869 4.73277 13.1698C5.50933 11.6189 8.32317 9.50655 11.3 11.3334C11.88 11.6903 13.1072 11.2906 13.9125 10.9481C17.8721 9.2877 21.8125 7.57975 25.7001 5.75761C26.6574 5.28798 27.4641 4.56316 28.0299 3.6643C30.0624 0.334031 31.788 -0.365327 35.551 0.37209C36.9891 0.681329 38.5806 0.186546 40.172 0.0390625ZM17.225 21.4146L12.8484 22.4232L10.7919 18.8456L7.51789 20.4394C7.96849 21.9332 8.32318 23.0988 8.67312 24.2692C8.1602 24.6783 7.68562 24.8924 7.46511 25.2635C7.05765 25.9533 6.39131 26.8811 6.59264 27.4377C6.79397 27.9943 8.0883 28.5367 8.67791 28.3606C10.2311 27.8849 10.8878 28.5985 11.837 29.6119C12.3547 30.1685 13.7256 29.9401 14.8617 30.0876C15.1349 28.0181 13.6873 25.8534 16.4916 24.9685C16.9901 24.8068 16.947 22.9275 17.225 21.4146ZM40.2583 14.6066C38.5757 13.7455 37.7513 12.9986 36.9507 13.0271C36.3563 13.0271 35.2107 14.2784 35.3305 14.6066C35.6182 15.4011 36.4426 16.4288 37.1664 16.5096C37.8903 16.5905 38.6764 15.6485 40.2583 14.6066ZM46.6243 10.8006C45.3348 9.97755 44.4672 9.05934 44.0213 9.22585C43.2256 9.53034 42.6888 10.4914 42.0416 11.1764C42.6552 11.7378 43.3406 12.813 43.8727 12.7417C44.678 12.6132 45.3779 11.714 46.6243 10.7768V10.8006ZM36.1167 10.7435L39.9947 11.4857C40.3255 10.263 40.5556 9.42091 40.8288 8.41707L37.4733 7.28954C37.0323 8.38377 36.7015 9.23061 36.1215 10.7197L36.1167 10.7435ZM44.3521 14.9111L41.289 13.9596C41.0541 15.3203 40.8864 16.3384 40.7282 17.2613C43.5804 18.779 44.2706 17.4136 44.3521 14.8873V14.9111Z'
      fill='#AEF55C'
    />
  </svg>
);

export default GameIcon;
