import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import GameIcon from '@/assets/svg/game-icon';

export default function Boost() {
  const { t } = useTranslation('pages/home');

  return (
    <div className='mx-auto w-full max-w-[1400px]'>
      <div className='flex flex-col items-center justify-between gap-10 p-5 lg:pb-40 ipad:flex-row'>
        <div className='flex w-full max-w-[635px] flex-col gap-y-9'>
          <div className='flex flex-col gap-y-5'>
            <div className='flex flex-col gap-y-6 text-xl font-[700] leading-none text-zinc-300'>
              <GameIcon />
              <span>{t('unlimited')}</span>
            </div>
            <h2 className='text-4xl font-[700] leading-none text-white'>{t('unlimited_subtitle')}</h2>
            <p className='text-base font-[500] leading-snug text-zinc-300'>{t('unlimited_description')}</p>
          </div>
          <Link href='/games' className='w-full cursor-pointer rounded-xl bg-gray-300 p-2.5 text-center text-sm font-bold text-white transition-all duration-200 hover:bg-opacity-90 hover:shadow-xl mobile:px-10 tablet:w-fit'>
            {t('unlimited_button')}
          </Link>
        </div>
        <Image alt='fire jet' src='/assets/images/boost/image.webp' width={741} height={716} />
      </div>
    </div>
  );
}
